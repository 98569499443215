<template>
    <modal 
    ref="modalLeecherosPrecio" 
    :fixHeight="60" 
    :titulo="`${$config.vendedor} que solicitaron cambio de precio`" 
    icon="leechero" 
    centerIcon 
    no-aceptar 
    no-cancelar
    >
        <div class="row mx-0 justify-center">
            <div class="col-12 text-center f-15 text-general">
                {{ fecha }}
            </div>
            <div class="col-auto d-middle f-500 text-general">
                <div class="bg-green br-10 mx-2" style="width:12px;height:12px;" />
                Aprobadas
            </div>
            <div class="col-auto d-middle f-500 text-general">
                <div class="bg-general br-10 mx-2" style="width:12px;height:12px;" />
                Rechazadas
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col-3 px-0 text-general f-14 f-500">
                {{ nombre }}
            </div>
            <div class="col-3 text-right text-general f-14 f-500">
                Solicitado
            </div>
            <div class="col-3 text-right text-general f-14 f-500">
                Solicitudes
            </div>
            <div class="col-3 text-right text-general f-14 f-500">
                Descuento
            </div>
        </div>
        <div v-for="(data,d) in datos" :key="d" class="row mx-0 my-3">
            <div class="col-3 px-0 text-general tres-puntos f-14 f-500">
                {{ data.nombre }}
            </div>
            <div class="col-3 text-right text-general f-14 f-500">
                {{ separadorNumero(data.total_descuento) }}
            </div>
            <div class="col-3 text-center text-general f-14 f-500">
                {{ agregarSeparadoresNumero(data.cantidad) }}
            </div>
            <div class="col-3 text-center text-general f-14 f-500">
                {{ agregarSeparadoresNumero(data.porcentaje_descuento,1) }}%
            </div>
            <div class="col-12 px-0 br-12 border-edit my-1" style="height:10px;">
                <div class="bg-gris br-12 h-100" :style="`width:${ agregarSeparadoresNumero(data.porcentaje_max_total_descuento)}%`" />
            </div>
            <div class="col-12 px-0">
                <div class="row mx-0 align-items-center">
                    <div class="col-auto px-0 text-general2 f-14">
                        {{ agregarSeparadoresNumero(data.porcentaje_aprobadas,1) }}%
                    </div>
                    <div class="col px-2 d-middle" style="height:5px;">
                        <div class="bg-green h-100 br-12" :style="`width:${agregarSeparadoresNumero(data.porcentaje_aprobadas)}%`" />
                        <div class="bg-general h-100 br-12" :style="`width:${agregarSeparadoresNumero(data.porcentaje_rechazadas)}%`" />
                    </div>
                    <div class="col-2 px-0 text-general2 f-14">
                        {{ agregarSeparadoresNumero(data.porcentaje_rechazadas,1) }}%
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import SugerenciasPrecios from "~/services/cambio_precio_estadisticas";
export default {
    data(){
        return {
            params: {
                fecha_inicio: null,
                fecha_fin: null,
                id_moneda: null,
                id_cedis: null,
                tipo: null,
            },
            datos: [],
            cargandoInforme: true,
            fecha: '',
        }
    },
    computed: {
        nombre(){
            let nombre = ''
            switch (this.params.tipo){
            case 1:
            case 2:
            case 3:
                nombre = this.$config.vendedor
                break;
            case 4:
                nombre = 'Producto'
                break;
            case 5:
                nombre = 'Categoria'
                break;
            case 6:
                nombre = this.$config.cliente
                break;

            default:
                nombre = ''
                break;
            }
            return nombre
        }
    },
    methods: {
        toggle(params){
            console.log(params);
            this.params = params
            this.fecha = `Entre : ${this.formatearFecha(params.fecha_inicio,'D MMM YYYY')} - ${this.formatearFecha(params.fecha_fin,'D MMM YYYY')}`

            this.verMasSugerenciasPrecios()
            this.$refs.modalLeecherosPrecio.toggle();
        },
        async verMasSugerenciasPrecios(){
            try {

                const { data } = await SugerenciasPrecios.verMasSugerenciasPrecios(this.params)
                this.datos = data.datos
            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoSolicitudes = false
            }
        },

    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px !important;
}
.border-edit{
    border: 1px solid #EEEEED !important;
}
</style>
